import { Component } from 'react'
import cn from 'classnames'
import PropTypes from 'meta/PropTypes'
import { Layout, Flex, Box } from 'react-flex-lite'
import { isHub } from 'lib/hub'
import { Title, Paragraph, Small } from 'components/display/Text'
import Breadcrumb from 'components/navigation/Breadcrumb'
import HelpDropdown from 'components/navigation/HelpDropdown'
import './index.scss'

@Layout
export default class Header extends Component {
  static propTypes = {
    title: PropTypes.string,
    notes: PropTypes.string,
    dark: PropTypes.bool,
    hub: PropTypes.bool,
    platform: PropTypes.bool,
    help: PropTypes.arrayOf(PropTypes.shape(HelpDropdown.Item.propTypes)),
    breadcrumb: PropTypes.shape(Breadcrumb.propTypes),
    icon: PropTypes.node,
    actions: PropTypes.node,
    children: PropTypes.node,
    className: PropTypes.string,
    message: PropTypes.string
  }

  static defaultProps = {
    platform: true,
    hub: true
  }

  render = () => {
    const {
      title, notes, icon, help,
      actions, breadcrumb, message, className,
      children, dark, platform, hub, ...rest
    } = this.props
    const areWePublic = isHub()
    if (!platform && !areWePublic) return null
    if (!hub && areWePublic) return null
    return <Box className={cn('header-component', className, { dark })} {...rest}>
      <Box className="inner-container">
        <Flex className="top-row" wrap justify="space-between" align="center">
          <Flex align="center" className="header-title" data-testid="header-title">
            {icon && <Flex align="center" mr={1} className="header-icon">
              {icon}
            </Flex>}
            {title && <Title truncate weight="normal" mr={breadcrumb || message ? 2 : undefined}>{title}</Title>}
            {breadcrumb && <Breadcrumb {...breadcrumb} />}
            {message && <Small truncate ml={breadcrumb || title ? 2 : undefined} >{message}</Small>}
          </Flex>
          {actions}
          {help?.length && !areWePublic && <HelpDropdown>
            {help.map((i, idx) => <HelpDropdown.Item key={idx} {...i} />)}
          </HelpDropdown>}
        </Flex>
        {notes && <Paragraph mt={2} truncate className="header-notes">{notes}</Paragraph>}
      </Box>
      {children}
    </Box>
  }
}

import store from 'meta/store'

export default function getUserRole(user) {
  if (!user) return null
  if (user.role === 'super_admin') return 'super_admin'
  if (user.role === 'support') return 'support'
  const place = store.getState().place
  return place && place.place_user && place.place_user.role.name
}

export const isSuperAdmin = (user) =>
  getUserRole(user) === 'super_admin'

export const isPlaceAdmin = (user) =>
  getUserRole(user) === 'content_admin' || 'organization_admin'

export const isSupport = (user) =>
  getUserRole(user) === 'support'

export const isAdmin = (user) =>
  isSuperAdmin(user) || isSupport(user) || isPlaceAdmin(user)

export const isContentAdmin = (user) =>
  getUserRole(user) === 'content_admin'

export const isOwner = (user, source) => {
  if (!user || !source) return false
  const userId = user.id
  const sourceUserId = source.userId
  return userId === sourceUserId
}

import store from 'meta/store'
import api from 'connections/api'

export const fetchRole = async (roleId) => {
  const { body } = await api.role.findById({ roleId })
  return body
}

export const fetchOperationsByGroupNames = (groupNames) => {
  const place = store.getState().place
  return api.operations.group({
    retry: 3,
    options: {
      groupNames: groupNames.join(','),
      placeId: place.id,
      refOnly: true
    }
  })
}

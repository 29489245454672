/* eslint react/forbid-prop-types: 0 */
import { PureComponent } from 'react'
import PropTypes from 'meta/PropTypes'
import { ToastContainer } from 'components/display/Toast'
import localStorage from 'connections/localStorage'
import moment from 'moment-timezone'
import getTimeZone from 'lib/getTimeZone'
import context from 'meta/context'
import config from 'meta/config'
import api, { raw } from 'connections/api'
import ErrorMessage from 'components/display/Error'
import Empty from 'components/display/Empty'
import Loader from 'components/display/Loader'
import { IconContext } from 'react-icons'
import { withRouter } from 'react-router-dom'
import analytics from 'connections/analytics'
import Page from 'components/layout/Page'
import { goTo, get as getRedirectTo } from 'lib/redirectTo'
import PlatformRoutes from 'routes/platform'
import HubRoutes from 'routes/hub'
import { fetchRole } from 'lib/commonApi'
import './index.scss'

const iconProps = { style: { verticalAlign: 'middle' } }

const getPlace = (user) => {
  if (!user) return
  if (user.places.length === 0) return
  const storedId = localStorage.getItem('placeId')
  if (storedId === 'personal-account') return // on personal acc
  const place = storedId && user.places.find(({ id }) => id === storedId)
  if (!place && user.places.length === 0) return
  return place || user.places[0]
}

@context([ 'place', 'login' ])
@withRouter
export default class Root extends PureComponent {
  static propTypes = {
    place: PropTypes.place,
    location: PropTypes.location.isRequired,
    login: PropTypes.func.isRequired
  }
  state = {
    loading: true,
    error: null,
    errorInfo: {}
  }
  UNSAFE_componentWillMount = async () => {
    this._started = Date.now()
    await this.getAuthState()
  }
  componentDidMount = () => {
    const now = Date.now()
    const render = now - this._started
    if (typeof window.performance !== 'undefined') {
      const perceived = now - performance.timing.navigationStart
      const networkToRender = now - performance.timing.fetchStart
      console.log('Timing', {
        'Navigation to Render': perceived,
        'Network to Render': networkToRender,
        'Ready to Render': render
      })
    } else {
      console.log('Timing', {
        'Ready to Render': render
      })
    }
  }
  componentDidUpdate = (prevProps) => {
    const bothHaveKeys = this.props.location.key && prevProps.location.key
    const keysDiffer = this.props.location.key != prevProps.location.key
    if (bothHaveKeys && keysDiffer) analytics.page()
  }
  componentDidCatch = (error, errorInfo) => {
    console.error('Root caught error!', error, errorInfo)
    this.setState({ error, errorInfo })
  }
  getAuthState = async () => {
    const redir = getRedirectTo()
    let res
    try {
      res = await raw.post('core/auth/state')
    } catch (err) {
      // 401 is fine
      if (err.response?.status === 401) {
        this.setState({ loading: false })
      }
      this.setState({ error: err })
      return
    }

    const body = await res.json()
    await this.setStoreState(body)
    moment.tz.setDefault(getTimeZone())
    this.setState({ loading: false, error: null })
    if (this.props.location.pathname === '/login') return
    if (redir) goTo(redir)
  }
  setStoreState = async (body) => {
    const user = body?.session?.user
    const isHub = !!body?.hub
    if (body?.hub?.invalid) return goTo(config.paths.public)
    const place = isHub
      ? { ...body.hub.place, isHub: true }
      : getPlace(user)

    if (place) {
      // resolve the boundary and tack it on, its too much BW for the server to do this for all available places
      const { body: boundary } = await api.boundary.findById({ boundaryId: place.boundaryId })
      if (place.place_user) {
        const roleOperations = await fetchRole(place.place_user.roleId)
        const place_user = {
          ...place,
          place_user: {
            ...place.place_user,
            role: roleOperations
          }
        }
        this.props.login(user, {
          ...place_user,
          boundary
        })
      } else {
        this.props.login(user, { ...place, boundary })
      }
    } else {
      this.props.login(user)
    }
  }
  renderMaintenance = () =>
    <Page center title={{ plain: true, title: 'System Maintenance' }}>
      <Empty
        title="Maintenance in progress"
        subtitle="We'll be back up and running as soon as possible!" />
    </Page>
  renderError = () => {
    if (this.state.error?.response?.status >= 500) {
      return this.renderMaintenance()
    }
    return <Page center title={{ plain: true, title: 'Error', statusCode: 500 }}>
      <ErrorMessage
        error={this.state.error}
        errorInfo={this.state.errorInfo} />
    </Page>
  }
  renderContent = () => {
    if (this.state.error) return this.renderError()
    if (this.state.loading) {
      return <Page center>
        <Loader />
      </Page>
    }

    return this.props.place?.isHub ? HubRoutes : PlatformRoutes
  }
  render = () =>
    <IconContext.Provider value={iconProps}>
      {this.renderContent()}
      <ToastContainer />
    </IconContext.Provider>
}

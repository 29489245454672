import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

const validationError = PropTypes.shape({
  validator: PropTypes.string,
  message: PropTypes.string.isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired
})
const ordering = PropTypes.shape({
  direction: PropTypes.oneOf([ 'asc', 'desc' ]).isRequired,
  value: PropTypes.object.isRequired
})
const theme = PropTypes.shape({
  squareImage: PropTypes.string,
  headerImage: PropTypes.string,
  logoImage: PropTypes.string
})
const place = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  displayName: PropTypes.string,
  boundary: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    area: PropTypes.shape({
      crs: PropTypes.shape(),
      type: PropTypes.string,
      coordinates: PropTypes.array
    })
  }),
  theme
})
const temporalRange = PropTypes.oneOfType([
  PropTypes.shape({
    start: PropTypes.Date,
    end: PropTypes.Date,
    type: PropTypes.string
  }),
  PropTypes.shape({
    type: PropTypes.string
  })
])
const valueRange = PropTypes.shape({
  min: PropTypes.number,
  max: PropTypes.number
})
const chartLayers = PropTypes.shape({
  mean: PropTypes.bool,
  median: PropTypes.bool,
  min: PropTypes.bool,
  max: PropTypes.bool
})
const dataType = PropTypes.shape({
  name: PropTypes.string,
  notes: PropTypes.string,
  schema: PropTypes.object
})
const insightOutput = PropTypes.shape({
  aggregations: PropTypes.arrayOf(PropTypes.string),
  groupings: PropTypes.arrayOf(PropTypes.object),
  orderings: PropTypes.arrayOf(ordering),
  schema: PropTypes.object
})
const insight = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  notes: PropTypes.string,
  visualization: PropTypes.string.isRequired,
  query: PropTypes.object,
  dataTypeId: PropTypes.string.isRequired,
  dataType: dataType.isRequired,
  output: insightOutput.isRequired
})
const LocalProps = {
  ...PropTypes,
  ...ReactRouterPropTypes,
  renderable: PropTypes.oneOfType([
    // forwardRef components
    PropTypes.shape({
      render: PropTypes.func.isRequired
    }),
    PropTypes.func
  ]),
  error: PropTypes.oneOfType([
    PropTypes.shape({
      message: PropTypes.string,
      name: PropTypes.string,
      stack: PropTypes.string
    }),
    PropTypes.string
  ]),

  // geojson
  featureCollection: PropTypes.shape({
    type: PropTypes.oneOf([ 'FeatureCollection' ]).isRequired,
    features: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string.isRequired,
      geometry: PropTypes.object
    })).isRequired
  }),

  // our api types
  validationError,
  validationErrors: PropTypes.arrayOf(validationError),
  ordering,
  orderings: PropTypes.arrayOf(ordering),
  singularResponse: PropTypes.object,
  collectionResponse: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.object).isRequired,
    meta: PropTypes.shape({
      results: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired
    }).isRequired
  }),

  // our models
  user: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.string.isRequired,
    image: PropTypes.string,
    authMethod: PropTypes.string,
    name: PropTypes.string,
    verified: PropTypes.bool,
    places: PropTypes.arrayOf(place)
  }),
  source: PropTypes.shape({
    name: PropTypes.string,
    dataTypeId: PropTypes.string
  }),
  collection: PropTypes.shape({
    name: PropTypes.string,
    notes: PropTypes.string,
    visibility: PropTypes.string
  }),
  dataType,
  place,
  temporalRange,
  valueRange,
  insight,
  insightOutput,
  chartLayers
}

export default LocalProps
